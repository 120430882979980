import { BackUpButtons, BackUpContent, BackUpWrapper, CommonProps } from './common';
import { AleoTypography } from '@aleohq/components';
import { Button, Form, Input, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import useAuth from '../../hooks/use-auth';
import { useAppSelector } from '../../store';
import { submitEmail } from '../../api/ceremony';

const Step4Gift = (props: CommonProps) => {
    const { user } = useAuth();
    const [form] = Form.useForm();
    const wallet = useAppSelector((state) => state.wallet);
    const [loading, setLoading] = useState(false);

    const submit = (data: { email: string }) => {
        if (wallet.address) {
            setLoading(true);

            submitEmail({
                ...data,
                address: wallet.address
            })
                .then(() => {
                    setLoading(false);
                    props.onNextStep();
                })
                .catch(() => {
                    setLoading(false);
                    notification.error({
                        message: 'Uh oh!',
                        description: 'There was an error submitting your email address. Please contact us on Discord.'
                    });
                });
        } else {
            notification.error({
                message: 'Hey!',
                description: 'You are trying to submit an email without an address. Please contribute to the setup!'
            });
        }
    };

    useEffect(() => {
        if (user) {
            form.setFieldsValue({
                email: user.email
            });
        }
    }, [user, form]);

    return (
        <BackUpWrapper>
            <BackUpContent>
                <AleoTypography textStyle="heading-3" style={{ width: 420 }}>
                    Thank you for your contribution to the setup.
                </AleoTypography>

                <AleoTypography textStyle="body" style={{ width: 445 }}>
                    Please enter your email address to receive a participation NFT as a token of our appreciation.
                </AleoTypography>

                <Form layout="vertical" style={{ width: 515, marginTop: 40 }} form={form} onFinish={submit}>
                    <Form.Item
                        label="Email Address"
                        name="email"
                        rules={[
                            { required: true, message: 'Email is required!' },
                            { type: 'email', message: 'Must be a valid email!' }
                        ]}
                    >
                        <Input placeholder="johnwick@gmail.com" size="large" />
                    </Form.Item>
                </Form>
            </BackUpContent>

            <BackUpButtons>
                <Button type="text" size="large" onClick={props.onPrevStep}>
                    Back
                </Button>

                <Button type="primary" size="large" onClick={form.submit} loading={loading}>
                    Continue
                </Button>
            </BackUpButtons>
        </BackUpWrapper>
    );
};

export default Step4Gift;
