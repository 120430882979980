import styled from 'styled-components';
import { gradients, palette } from '@aleohq/ui/dist/palette';
import { Button, Layout, Progress, Space } from 'antd';
import {
    HeaderButton,
    Logo,
    Step2Contribute,
    Step3BackUp,
    Step4Gift,
    Step5Tweet,
    UnsupportedBrowserModal
} from '../components';
import { AleoTypography } from '@aleohq/components';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Step6Done from '../components/InnerSetupSteps/Step6Done';
import { detect } from 'detect-browser';

const browser = detect();

interface StepMap {
    [key: number]: React.ReactNode;
}

const InnerSetupWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    z-index: 10;

    background: ${palette.gray1};
`;

const InnerSetupLayout = styled(Layout)`
    max-width: unset;
    min-height: 100vh;
`;

const InnerSetupHeader = styled(Layout.Header)`
    height: 93px;
    display: flex;
    align-items: center;
    max-width: 1110px;
    width: 100%;
    margin: auto;

    & > div {
        flex: 1;
        display: flex;

        &:nth-child(2) {
            justify-content: center;
        }

        &:last-child {
            justify-content: flex-end;
        }
    }
`;

const InnerSetupContent = styled(Layout.Content)`
    max-width: 1110px;
    width: 100%;
    margin: 100px auto 0 auto;
    display: flex;
    flex-direction: column;
`;

const StepProgress = styled(Progress)`
    .ant-progress-inner {
        background-color: ${palette.gray7} !important;
        border-radius: 0;
    }

    .ant-progress-bg {
        height: 4px !important;
        background: ${gradients.greenGradient} !important;
    }
`;

const InnerSetup = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [unsupportedBrowser, setUnsupportedBrowser] = useState(false);

    const nextStep = () => setCurrentStep((prev) => Math.min(prev + 1, 4));

    const prevStep = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

    const stepMap: StepMap = {
        0: <Step2Contribute onNextStep={nextStep} />,
        // 1: <RickRoll />,
        1: <Step3BackUp onNextStep={nextStep} />,
        2: <Step4Gift onNextStep={nextStep} onPrevStep={prevStep} />,
        3: <Step5Tweet onNextStep={nextStep} />,
        4: <Step6Done />
    };

    useEffect(() => {
        const version = Math.floor(Number(browser?.version));

        switch (browser?.name) {
            case 'chrome':
            case 'edge':
                if (version < 83) {
                    setUnsupportedBrowser(true);
                    return;
                }

                break;
            case 'firefox':
                if (version < 79) {
                    setUnsupportedBrowser(true);
                    return;
                }

                break;
            default:
                setUnsupportedBrowser(true);
                return;
        }
    }, []);

    return (
        <InnerSetupWrapper>
            <InnerSetupLayout>
                <InnerSetupHeader>
                    <div>
                        <Link to="/">
                            <Logo />
                        </Link>
                    </div>
                    <div>
                        <AleoTypography textStyle="body" noMargin>
                            Step {currentStep + 1} of 5
                        </AleoTypography>
                    </div>
                    <div>
                        <Space>
                            {currentStep < 2 && (
                                <Link to="/">
                                    <Button size="large">Cancel</Button>
                                </Link>
                            )}

                            {currentStep >= 4 && (
                                <Space size={40}>
                                    <Link to="/stats">
                                        <HeaderButton type="link" size="large">
                                            Setup Stats
                                        </HeaderButton>
                                    </Link>

                                    <Link to="/status">
                                        <HeaderButton type="link" size="large">
                                            Status
                                        </HeaderButton>
                                    </Link>

                                    <a
                                        href="https://aleo.org/post/announcing-aleo-setup"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <HeaderButton type="link" size="large">
                                            Blog
                                        </HeaderButton>
                                    </a>

                                    <Link to="/faq">
                                        <HeaderButton type="link" size="large">
                                            FAQ
                                        </HeaderButton>
                                    </Link>
                                </Space>
                            )}
                        </Space>
                    </div>
                </InnerSetupHeader>

                <StepProgress percent={20 * (currentStep + 1)} showInfo={false} size="small" />

                <InnerSetupContent>{!unsupportedBrowser && stepMap[currentStep]}</InnerSetupContent>

                <UnsupportedBrowserModal visible={unsupportedBrowser} onCancel={() => setUnsupportedBrowser(false)} />
            </InnerSetupLayout>
        </InnerSetupWrapper>
    );
};

export default InnerSetup;
