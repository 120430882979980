import { AleoTypography } from '@aleohq/components';
import styled from 'styled-components';
import { Progress } from 'antd';
import { gradients } from '@aleohq/ui/dist/palette';
import { CommonProps } from './common';
import React, { useCallback, useEffect, useState } from 'react';
// @ts-ignore
import InnerSetupWorker from '../../worker/inner-setup.worker';
// @ts-ignore
import KeyGenWorker from '../../worker/keys.worker';
import { AttestationsTable } from '../index';
import { useAppDispatch, useAppSelector } from '../../store';
import { setWalletData } from '../../store/wallet.slice';
import { ceremonyStatus } from '../../api/ceremony';
import { getFormattedTime } from '../../lib/time';
import { useStatus } from '../../api/status';
import dayjs from 'dayjs';

type Status = 'INIT' | 'QUEUE' | 'ROUND' | 'FINISHED' | 'OTHER';

const SetupProgress = styled(Progress)`
    margin-top: 65px;
    margin-bottom: 10px;

    .ant-progress-bg {
        height: 21px !important;
        background: ${gradients.greenGradient};
    }
`;

const Step2Contribute = (props: CommonProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [status, setStatus] = useState<Status>('INIT');
    const [queuePosition, setQueuePosition] = useState<number>();
    const [estWaitTime, setEstWaitTime] = useState<string>();
    const [percentComplete, setPercentComplete] = useState<number>();
    const [estFinishTime, setEstFinishTime] = useState<string>();
    const [worker, setWorker] = useState<Worker>();
    const [keyGenWorker, setKeyGenWorker] = useState<Worker>();
    const { coordinatorData, stateData } = useStatus('inner');
    const wallet = useAppSelector((state) => state.wallet);
    const dispatch = useAppDispatch();

    const pollForStatus = useCallback(
        (address: string) => {
            const timer = setInterval(() => {
                ceremonyStatus({
                    address
                })
                    .then((res) => {
                        if (typeof res.data === 'object') {
                            setStatus('QUEUE');
                            setQueuePosition(res.data.Queue[0]);

                            const { value, unit } = getFormattedTime(res.data.Queue[0] * 4500);
                            setEstWaitTime(`${value} ${unit}`);
                        } else {
                            setStatus(res.data.toUpperCase() as Status);

                            if (res.data === 'Finished') {
                                props.onNextStep();
                            }
                        }
                    })
                    .catch(console.error);
            }, 1000);

            return timer;
        },
        [props]
    );

    useEffect(() => {
        if (coordinatorData && stateData) {
            const chunksComplete = stateData.chunks.filter(
                (c) => c.contributions[1] !== undefined && c.contributions[1].verified
            ).length;

            const percentComplete = (chunksComplete / stateData.chunks.length) * 100;

            const finishTime = dayjs().to(
                dayjs().add(coordinatorData.current_metrics.estimated_finish_time, 'seconds'),
                true
            );

            setPercentComplete(percentComplete);
            setEstFinishTime(finishTime);
        }
    }, [coordinatorData, stateData]);

    useEffect(() => {
        const worker: Worker = new InnerSetupWorker();
        setWorker(worker);

        const keyWorker: Worker = new KeyGenWorker();
        setKeyGenWorker(keyWorker);

        return () => {
            worker.terminate();
            keyWorker.terminate();
        };
    }, []);

    useEffect(() => {
        if (worker) {
            worker.onmessage = (ev: MessageEvent<string>) => {
                console.log(ev.data);
            };
        }
    }, [dispatch, worker]);

    useEffect(() => {
        if (keyGenWorker) {
            keyGenWorker.onmessage = (ev: MessageEvent<string[]>) => {
                const [address, privateKey, confKeyHash, privateConfKey] = ev.data;

                dispatch(
                    setWalletData({
                        address,
                        privateKey,
                        confKeyHash,
                        privateConfKey
                    })
                );
            };
        }
    }, [dispatch, keyGenWorker, worker]);

    useEffect(() => {
        if (worker && keyGenWorker) {
            if (wallet.privateKey && wallet.confKeyHash) {
                worker.postMessage({
                    privateKey: wallet.privateKey,
                    confKeyHash: wallet.confKeyHash
                });
            }
        }
    }, [worker, keyGenWorker, wallet]);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (wallet && wallet.address) {
            console.log('Starting to poll');
            timer = pollForStatus(wallet.address);
        }

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [wallet, pollForStatus]);

    const Init = (
        <div>
            <AleoTypography textStyle="heading-3">Loading Ceremony...</AleoTypography>
            <AleoTypography textStyle="body">Please wait a moment while the Setup Ceremony initializes.</AleoTypography>
        </div>
    );

    const Queue = (
        <div>
            <AleoTypography textStyle="heading-3">
                Congrats! You are number
                <br />
                {queuePosition} in the queue.
            </AleoTypography>
            <AleoTypography textStyle="body" noMargin>
                Expected wait time:{' '}
                <span className="primary-text">
                    <b>{estWaitTime}</b>
                </span>
            </AleoTypography>
            <AleoTypography textStyle="body">Please leave this window open.</AleoTypography>

            <AttestationsTable simple />
        </div>
    );

    const InnerRunning = (
        <div>
            <AleoTypography textStyle="heading-3" style={{ width: 490 }}>
                Please keep your browser open while the setup is running
            </AleoTypography>

            <AleoTypography textStyle="body" style={{ width: 665 }}>
                Your computer is now contributing randomness to the public parameters of Aleo. Thanks to your help,
                programs on Aleo will be able to run privately, trustlessly, and fast!
            </AleoTypography>

            <SetupProgress percent={percentComplete} showInfo={false} />
            <AleoTypography textStyle="body-small" type="secondary">
                About {estFinishTime} remaining
            </AleoTypography>
        </div>
    );

    return {
        QUEUE: Queue,
        ROUND: InnerRunning,
        FINISHED: <div>Done!</div>,
        INIT: Init,
        OTHER: Init
    }[status];
};

export default Step2Contribute;
