import React from 'react';
import { BackUpButtons, BackUpContent, BackUpProps, BackUpWrapper } from '../common';
import { AleoTypography } from '@aleohq/components';
import { Button } from 'antd';

const BackUpSuccess = (props: BackUpProps) => {
    return (
        <BackUpWrapper>
            <BackUpContent>
                <AleoTypography textStyle="heading-3" style={{ width: 340 }}>
                    You've successfully backed up your wallet.
                </AleoTypography>

                <AleoTypography textStyle="body" style={{ width: 410 }}>
                    Once Aleo mainnet is live, participants in the Aleo setup will receive a special thank you gift.
                </AleoTypography>
            </BackUpContent>

            <BackUpButtons>
                <Button type="text" size="large" onClick={() => props.onBack()}>
                    Back
                </Button>

                <Button type="primary" size="large" onClick={() => props.onNext('SUCCESS')}>
                    Continue
                </Button>
            </BackUpButtons>
        </BackUpWrapper>
    );
};

export default BackUpSuccess;
