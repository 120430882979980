import React from 'react';
import { Layout, Space } from 'antd';
import { Footer, HeaderButton } from './components';
import logo from './assets/logo.png';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import Stats from './routes/Stats';
import FAQ from './routes/FAQ';
import Error404 from './routes/404';
import Error500 from './routes/500';
import InnerSetup from './routes/InnerSetup';
import Status from './routes/Status';
import { AleoHeader } from '@aleohq/components';

const AleoLayout = styled(Layout)`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const ContentWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const Links = styled(Space)`
    gap: 50px !important;

    @media (max-width: 800px) {
        gap: 20px !important;
    }
`;

const App = () => {
    const routes = [
        {
            path: '/',
            component: <InnerSetup />
        },
        {
            path: '/stats',
            component: <Stats />
        },
        {
            path: '/faq',
            component: <FAQ />
        },
        {
            path: '/status',
            component: <Status />
        },
        {
            path: '/500',
            component: <Error500 />
        }
    ];

    return (
        <BrowserRouter>
            <AleoLayout>
                <AleoHeader logo={logo} logoRender={(logo) => <Link to="/">{logo}</Link>}>
                    <div />

                    <Links>
                        <Link to="/stats">
                            <HeaderButton type="link" size="large">
                                Setup Stats
                            </HeaderButton>
                        </Link>

                        <Link to="/status">
                            <HeaderButton type="link" size="large">
                                Status
                            </HeaderButton>
                        </Link>

                        <a href="https://aleo.org/post/announcing-aleo-setup" target="_blank" rel="noreferrer">
                            <HeaderButton type="link" size="large">
                                Blog
                            </HeaderButton>
                        </a>

                        <Link to="/faq">
                            <HeaderButton type="link" size="large">
                                FAQ
                            </HeaderButton>
                        </Link>
                    </Links>
                </AleoHeader>

                <ContentWrapper>
                    <Switch>
                        {routes.map((route) => (
                            <Route key={route.path} path={route.path} exact>
                                {route.component}
                            </Route>
                        ))}

                        <Route component={Error404} />
                    </Switch>
                </ContentWrapper>

                <Footer />
            </AleoLayout>
        </BrowserRouter>
    );
};

export default App;
