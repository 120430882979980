import { AleoTypography } from '@aleohq/components';
import React, { ReactElement, useState } from 'react';
import { BackUpPage, ButtonRow, CommonProps } from './common';
import { Button, Modal } from 'antd';
import BackUpMenu from './BackUpWallet/BackUpMenu';
import BackUpMnemonic from './BackUpWallet/BackUpMnemonic';
import BackUpSuccess from './BackUpWallet/BackUpSuccess';
import BackUpPrivateKey from './BackUpWallet/BackUpPrivateKey';
import BackUpJSON from './BackUpWallet/BackUpJSON';
import BackUpPrivateKeyOnly from './BackUpWallet/BackUpPrivateKeyOnly';

interface SuccessProps extends CommonProps {
    startBackingUp: () => void;
}

type BackUpMap = {
    [key in BackUpPage]: React.ReactNode;
};

const ContributionSuccess = (props: SuccessProps) => {
    const skip = () => {
        Modal.confirm({
            title: <AleoTypography textStyle="subtitle">Are you sure you wish to skip the back up?</AleoTypography>,
            content: (
                <AleoTypography textStyle="body">
                    By not backing up your wallet you will be unable to prove your participation in the Aleo Setup
                    Ceremony.
                </AleoTypography>
            ),
            centered: true,
            icon: null,
            closable: true,
            maskClosable: true,
            width: 520,
            okButtonProps: {
                size: 'large',
                type: 'primary',
                danger: true
            },
            cancelButtonProps: {
                size: 'large',
                type: 'text'
            },
            okText: "Yes, I'm sure",
            cancelText: 'No, take me back!',
            className: 'setup-modal',
            onOk: props.onNextStep
        });
    };

    return (
        <div>
            <AleoTypography textStyle="heading-3" style={{ width: 420 }}>
                Success! Thank you for your contribution to the setup.
            </AleoTypography>

            <AleoTypography textStyle="body" style={{ width: 310 }}>
                An Aleo wallet has been created for you. Backing up your wallet is recommended.
            </AleoTypography>

            <ButtonRow>
                <Button size="large" type="primary" onClick={props.startBackingUp}>
                    Back Up Wallet
                </Button>
                <AleoTypography textStyle="body" noMargin>
                    or
                </AleoTypography>
                <Button size="large" onClick={skip}>
                    Skip
                </Button>
            </ButtonRow>
        </div>
    );
};

const BackUpWallet = (props: CommonProps & { onCancel: () => void }) => {
    const [activePage, setActivePage] = useState<BackUpPage>('PRIVATE_KEY_ONLY');

    const backUpMap: BackUpMap = {
        PRIVATE_KEY_ONLY: (
            <BackUpPrivateKeyOnly onBack={props.onCancel} onNext={(page) => setActivePage(page ?? 'SUCCESS')} />
        ),
        MENU: <BackUpMenu onNext={(page) => setActivePage(page ?? 'SUCCESS')} onBack={props.onCancel} />,
        MNEMONIC: (
            <BackUpMnemonic onNext={(page) => setActivePage(page ?? 'SUCCESS')} onBack={() => setActivePage('MENU')} />
        ),
        PRIVATE_KEY: (
            <BackUpPrivateKey
                onNext={(page) => setActivePage(page ?? 'SUCCESS')}
                onBack={() => setActivePage('MENU')}
            />
        ),
        JSON_FILE: (
            <BackUpJSON onNext={(page) => setActivePage(page ?? 'SUCCESS')} onBack={() => setActivePage('MENU')} />
        ),
        SUCCESS: <BackUpSuccess onNext={props.onNextStep} onBack={() => setActivePage('MENU')} />
    };

    return backUpMap[activePage] as ReactElement;
};

const Step3BackUp = (props: CommonProps) => {
    const [isBackingUp, setBackingUp] = useState(false);

    return isBackingUp ? (
        <BackUpWallet onNextStep={props.onNextStep} onCancel={() => setBackingUp(false)} />
    ) : (
        <ContributionSuccess onNextStep={props.onNextStep} startBackingUp={() => setBackingUp(true)} />
    );
};

export default Step3BackUp;
