import useSWR from 'swr';
import { getFormattedTime, normalizeTranscriptSizes } from '../lib/time';
import { useEffect, useState } from 'react';

export type CeremonyType = 'inner' | 'outer' | 'universal' | 'all';

export interface Contributor {
    address: string;
    roundStartTime: number;
    roundCompleteTime: number;
    attestationLink: string;
    setupType: string;
}

export interface StatsResponse {
    transcriptSize: string;
    averageContributionTime: number;
    contributorData: Contributor[];
}

interface StatsResponseWithUnits extends StatsResponse {
    transcriptUnit: string;
    averageContributionUnit: string;
}

export const useStats = (ceremony: CeremonyType) => {
    const { data: universalData, error: universalError } = useSWR<StatsResponse>(
        'https://ceremony.aleo.org/stats/v1/get_stats'
    );

    const { data: outerData, error: outerError } = useSWR<StatsResponse>(
        'https://outer-ceremony.aleo.org/stats/v1/get_stats'
    );

    const { data: innerData, error: innerError } = useSWR<StatsResponse>(
        'https://inner-ceremony.aleo.org/stats/v1/get_stats'
    );

    const [stats, setStats] = useState<StatsResponseWithUnits>();

    useEffect(() => {
        let data: StatsResponse | undefined;

        switch (ceremony) {
            case 'outer':
                data = outerData;
                break;
            case 'universal':
                data = universalData;
                break;
            case 'inner':
                data = innerData;
                break;
            case 'all':
                if (outerData && universalData && innerData) {
                    const averageContributionTime =
                        (outerData.averageContributionTime +
                            universalData.averageContributionTime +
                            innerData.averageContributionTime) /
                        3;
                    const transcriptSize = normalizeTranscriptSizes(
                        outerData.transcriptSize,
                        universalData.transcriptSize,
                        innerData.transcriptSize
                    );
                    const contributorData = [
                        ...outerData.contributorData,
                        ...universalData.contributorData,
                        ...innerData.contributorData
                    ].sort((a, b) => {
                        return a.roundCompleteTime - b.roundCompleteTime;
                    });

                    data = {
                        averageContributionTime,
                        transcriptSize,
                        contributorData
                    };
                }
        }

        if (data) {
            const transcriptSize = data.transcriptSize.split(' ')[0];
            const transcriptUnit = data.transcriptSize.split(' ')[1];
            const contributionTime = getFormattedTime(data.averageContributionTime);

            setStats({
                ...data,
                transcriptSize,
                transcriptUnit,
                averageContributionTime: contributionTime.value,
                averageContributionUnit: contributionTime.unit
            });
        }
    }, [universalData, outerData, innerData, ceremony]);

    return {
        stats,
        isLoading: !stats && !universalError && !outerError && !innerError,
        error: universalError
    };
};
