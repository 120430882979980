import React from 'react';
import { BackUpButtons, BackUpContent, BackUpProps, BackUpWrapper } from '../common';
import { AleoTypography } from '@aleohq/components';
import { Button } from 'antd';

const BackUpJSON = (props: BackUpProps) => {
    return (
        <BackUpWrapper>
            <BackUpContent>
                <AleoTypography textStyle="heading-3" style={{ width: 360 }}>
                    The JSON file has been downloaded
                </AleoTypography>

                <AleoTypography textStyle="body" style={{ maxWidth: 500, marginBottom: 40 }}>
                    Please keep it secure.
                </AleoTypography>

                <Button size="large" type="primary">
                    Download Again
                </Button>
            </BackUpContent>

            <BackUpButtons>
                <Button type="text" size="large" onClick={() => props.onBack()}>
                    Back
                </Button>

                <Button type="primary" size="large" onClick={() => props.onNext('SUCCESS')}>
                    Continue
                </Button>
            </BackUpButtons>
        </BackUpWrapper>
    );
};

export default BackUpJSON;
