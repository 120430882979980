import React from 'react';
import { AleoTypography } from '@aleohq/components';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const Step6Done = () => {
    return (
        <div>
            <AleoTypography textStyle="heading-3" style={{ maxWidth: 340 }}>
                Thank you for your contribution.
            </AleoTypography>
            <AleoTypography textStyle="body">You're done! You may close the tab.</AleoTypography>

            <Link to="/stats">
                <Button type="primary" size="large" style={{ marginTop: 20 }}>
                    View Stats
                </Button>
            </Link>
        </div>
    );
};

export default Step6Done;
