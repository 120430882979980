import React, { useEffect, useState } from 'react';
import { TableHeader } from '../common';
import { AleoTypography } from '@aleohq/components';
import styled from 'styled-components';
import { Card, Select } from 'antd';
import { palette } from '@aleohq/ui/dist/palette';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import dayjs from 'dayjs';
import { Contributor } from '../../api/stats';
import { isBrowser, isMobileOnly } from 'react-device-detect';

type Mode = 'stacked' | 'split';

interface StatsData {
    inner: number;
    outer: number;
    universal: number;
    label: string;
}

interface StatsByMonth {
    [month: number]: {
        [day: number]: StatsData;
    };
}

interface GraphProps {
    contributors: Contributor[];
    setupType: string;
}

const GraphCard = styled(Card)`
    background: ${palette.gray3};
    border-radius: 0 0 20px 20px;
    border: none;

    .ant-card-body {
        min-height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 30px 10px 20px;

        @media (max-width: 800px) {
            overflow-x: scroll;
            padding: 10px;
        }
    }
`;

const EmptyCard = styled(GraphCard)`
    .ant-card-body {
        padding: 0;
    }
`;

const ChartTooltip = styled.div`
    background: ${palette.gray4};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 15px;
`;

const StyledLabel = styled.div`
    display: flex;
    gap: 5px;
    font-size: 14px;
    line-height: 22px;
    align-items: center;

    @media (max-width: 800px) {
        font-size: 12px;
        line-height: 20px;
    }
`;

const LabelIcon = styled.div<{ labelColor: string }>`
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: ${(props) => props.labelColor};
`;

const LabelRow = styled.div`
    display: flex;
    gap: 20px;
    margin-right: 40px;

    @media (max-width: 800px) {
        margin-right: 0;
        gap: 10px;
        justify-content: flex-end;
        margin-top: 35px;
    }
`;

const MobileStatsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > div {
        display: flex;
        align-items: center;
    }
`;

const CardSelect = styled(Select)`
    &.ant-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background: ${palette.gray4};
    }
`;

const GraphLabel = (props: { labelColor: string; label: string }) => {
    return (
        <StyledLabel>
            <LabelIcon labelColor={props.labelColor} />
            {props.label}
        </StyledLabel>
    );
};

const ContributionGraph = (props: GraphProps) => {
    const [formattedStats, setFormattedStats] = useState<StatsByMonth>({});
    const [selectedMonth, setSelectedMonth] = useState<number>(-1);
    const [mode, setMode] = useState<Mode>('stacked');
    const [statsForSelectedMonth, setStatsForSelectedMonth] = useState<StatsData[]>();

    useEffect(() => {
        const sortedContributors = [...props.contributors]
            .filter((contrib) => {
                return props.setupType === 'all' ? contrib : contrib.setupType === props.setupType;
            })
            .sort((a, b) => {
                return a.roundCompleteTime - b.roundCompleteTime;
            });

        const months = new Set();
        sortedContributors.forEach((c) => months.add(dayjs(c.roundStartTime * 1000).month()));

        const statsByMonth: StatsByMonth = {};

        const today = dayjs();
        for (const month of Array.from(months) as number[]) {
            // Could present problems if we have contributors in 2022. Unlikely!
            for (let day = 1; day <= dayjs(`2021-${month + 1}-01`).daysInMonth(); day++) {
                if (!statsByMonth[month]) {
                    statsByMonth[month] = {};
                }

                statsByMonth[month][day] = { label: day.toString(10), inner: 0, outer: 0, universal: 0 };

                if (today.month() === month && today.date() === day) {
                    break;
                }
            }
        }

        for (const contributor of sortedContributors) {
            const date = dayjs(contributor.roundStartTime * 1000);
            const month = date.month();
            const day = date.date();

            if (contributor.setupType === 'universal') {
                statsByMonth[month][day].universal += 1;
            }

            if (contributor.setupType === 'outer') {
                statsByMonth[month][day].outer += 1;
            }

            if (contributor.setupType === 'inner') {
                statsByMonth[month][day].inner += 1;
            }
        }

        let universalCumulative = 0;
        let outerCumulative = 0;
        let innerCumulative = 0;

        for (const month in statsByMonth) {
            for (const day in statsByMonth[month]) {
                universalCumulative += statsByMonth[month][day].universal;
                outerCumulative += statsByMonth[month][day].outer;
                innerCumulative += statsByMonth[month][day].inner;

                statsByMonth[month][day].universal = universalCumulative;
                statsByMonth[month][day].outer = outerCumulative;
                statsByMonth[month][day].inner = innerCumulative;
            }
        }

        setFormattedStats(statsByMonth);
    }, [props]);

    useEffect(() => {
        if (selectedMonth === -1) {
            const all: StatsData[] = [];

            for (const month in formattedStats) {
                for (const day in formattedStats[month]) {
                    all.push({
                        ...formattedStats[month][day],
                        label: `${+month + 1}/${day}`
                    });
                }
            }

            setStatsForSelectedMonth(all);
        } else {
            const statsForMonth = formattedStats[selectedMonth];

            if (statsForMonth) {
                setStatsForSelectedMonth(Object.values(statsForMonth));
            } else {
                setStatsForSelectedMonth([]);
            }
        }
    }, [formattedStats, selectedMonth]);

    const getSelectOptions = () => {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        const options: React.ReactNode[] = [];

        for (const month in formattedStats) {
            options.push(
                <Select.Option value={+month} key={month}>
                    {months[month]}
                </Select.Option>
            );
        }

        return (
            <CardSelect
                value={Object.keys(formattedStats).length === 0 ? -1 : selectedMonth}
                onChange={(e: any) => setSelectedMonth(e)}
                style={{ minWidth: 150 }}
                size="large"
                disabled={Object.keys(formattedStats).length === 0}
            >
                <Select.Option value={''} disabled>
                    Select Month
                </Select.Option>
                <Select.Option value={-1}>All</Select.Option>
                {options}
            </CardSelect>
        );
    };

    return (
        <div>
            <TableHeader>
                {isBrowser && (
                    <>
                        <AleoTypography textStyle="body" mobileTextStyle="body-small" noMargin>
                            Cumulative Contributions
                        </AleoTypography>

                        <div style={{ flex: 1 }} />

                        <LabelRow>
                            <GraphLabel labelColor={palette.green6} label="Universal Setup" />
                            <GraphLabel labelColor={palette.blue6} label="Outer Setup" />
                            <GraphLabel labelColor={palette.pink6} label="Inner Setup" />
                        </LabelRow>

                        <CardSelect
                            value={mode}
                            onChange={(e) => setMode(e as Mode)}
                            size="large"
                            style={{ minWidth: 120, marginRight: 10 }}
                        >
                            <Select.Option value="stacked">Stacked</Select.Option>
                            <Select.Option value="split">Split</Select.Option>
                        </CardSelect>

                        {getSelectOptions()}
                    </>
                )}

                {isMobileOnly && (
                    <MobileStatsWrapper>
                        <div>
                            <AleoTypography textStyle="body" mobileTextStyle="body-small" noMargin>
                                Cumulative Contributions
                            </AleoTypography>

                            <div style={{ flex: 1 }} />

                            {getSelectOptions()}
                        </div>

                        <LabelRow>
                            <GraphLabel labelColor={palette.green6} label="Universal Setup" />
                            <GraphLabel labelColor={palette.blue6} label="Outer Setup" />
                            <GraphLabel labelColor={palette.pink6} label="Inner Setup" />
                        </LabelRow>
                    </MobileStatsWrapper>
                )}
            </TableHeader>

            {(statsForSelectedMonth?.length ?? 0) > 0 && (
                <GraphCard>
                    <ResponsiveContainer height={416}>
                        <AreaChart data={statsForSelectedMonth} height={416}>
                            <XAxis dataKey="label" stroke={palette.gray9} />
                            <YAxis stroke={palette.gray9} type="number" width={40} />
                            <CartesianGrid vertical={false} stroke={palette.gray5} strokeWidth={1} />
                            <Tooltip
                                content={(content) => {
                                    const uni = (content?.payload?.[0]?.value as number) ?? 0;
                                    const outer = (content?.payload?.[1]?.value as number) ?? 0;
                                    const inner = (content?.payload?.[2]?.value as number) ?? 0;

                                    return (
                                        <ChartTooltip>
                                            <div>
                                                <b>Universal Setup:</b> {uni} total contributions
                                            </div>
                                            <div>
                                                <b>Outer Setup:</b> {outer} total contributions
                                            </div>
                                            <div>
                                                <b>Inner Setup:</b> {inner} total contributions
                                            </div>
                                        </ChartTooltip>
                                    );
                                }}
                                cursor={{ fill: palette.gray4 }}
                            />

                            <Area
                                type="monotone"
                                dataKey={
                                    props.setupType === 'universal' || props.setupType === 'all' ? 'universal' : ''
                                }
                                stroke={palette.green6}
                                strokeWidth={2}
                                fill={palette.green6}
                                fillOpacity={0.3}
                                stackId="1"
                            />
                            <Area
                                type="monotone"
                                dataKey={props.setupType === 'outer' || props.setupType === 'all' ? 'outer' : ''}
                                stroke={palette.blue6}
                                strokeWidth={2}
                                fill={palette.blue6}
                                fillOpacity={0.3}
                                stackId={mode === 'stacked' ? '1' : '2'}
                            />
                            <Area
                                type="monotone"
                                dataKey={props.setupType === 'inner' || props.setupType === 'all' ? 'inner' : ''}
                                stroke={palette.pink6}
                                strokeWidth={2}
                                fill={palette.pink6}
                                fillOpacity={0.3}
                                stackId={mode === 'stacked' ? '1' : '3'}
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </GraphCard>
            )}

            {(!statsForSelectedMonth || statsForSelectedMonth.length === 0) && (
                <EmptyCard>
                    <AleoTypography textStyle="body" type="secondary" noMargin>
                        There are no records to display
                    </AleoTypography>
                </EmptyCard>
            )}
        </div>
    );
};

export default ContributionGraph;
