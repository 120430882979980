import { useAppDispatch, useAppSelector } from '../store';
import { authenticateUser, fetchCurrentUser, logoutUser, registerUser } from '../store/user.slice';
import { useCallback } from 'react';

export interface SignInData {
    password: string;
    username: string;
}

export interface SignUpData {
    email: string;
    password: string;
    username: string;
}

const useAuth = () => {
    const dispatch = useAppDispatch();
    const { user, error, loading } = useAppSelector((state) => state.auth);

    const isSignedIn = () => {
        return loading || (!error && user);
    };

    const getCurrentUser = useCallback(async () => {
        await dispatch(fetchCurrentUser());
    }, [dispatch]);

    const login = useCallback(
        async (data: SignInData) => {
            const loginResult = await dispatch(
                authenticateUser({
                    email_username: data.username,
                    password: data.password
                })
            );

            if (authenticateUser.fulfilled.match(loginResult)) {
                return loginResult.payload;
            } else {
                throw new Error('You provided an invalid username/password combination. Please try again!');
            }
        },
        [dispatch]
    );

    const register = useCallback(
        async (data: SignUpData) => {
            const registerResult = await dispatch(registerUser(data));

            if (registerUser.fulfilled.match(registerResult)) {
                return registerResult.payload;
            } else {
                throw new Error(
                    'There was an error creating your account. If you already have an account, please sign in!'
                );
            }
        },
        [dispatch]
    );

    const signOut = useCallback(() => {
        localStorage.removeItem('apm-token');
        dispatch(logoutUser());
    }, [dispatch]);

    return {
        user,
        userError: error,
        userLoading: loading,
        login,
        register,
        getCurrentUser,
        signOut,
        isSignedIn
    };
};

export default useAuth;
